import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

@font-face {
    font-family: 'Fredoka One';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/fredoka-one-v8-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/fredoka-one-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/fredoka-one-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/fredoka-one-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('/fonts/fredoka-one-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('/fonts/fredoka-one-v8-latin-regular.svg#FredokaOne') format('svg'); /* Legacy iOS */
  }

    @font-face {
        font-family: "Lato Regular";
        src: url("/fonts/Lato-Regular.ttf") format("truetype");
        font-style: normal;
    }


    body ,
    html,
    a {
        font-family: 'Lato-Regular', sans-serif;
    }
    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #0099cc;
        overflow-x: hidden;
    }

    a:hover {
        color: white;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            // background: none;
            box-shadow: white 0px 0px 0px 1px;
        }
    }

    h1,
    // h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Fredoka One', cursive;
        color: #FFFFFF;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    h2 {
        font-family: 'Fredoka One', cursive;
        color: #FFFFFF;
        font-size: 24px;
        line-height: 1.41;

        @media only screen and (max-width: 890px) {
          font-size: 24px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 24px;
        }
    }

    h3 {
        font-family: 'Fredoka One', cursive;
        color: #000000;
        font-size: 18px;
        line-height: 1.41;

        @media only screen and (max-width: 890px) {
          font-size: 18px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 18px;
        }
    }

    li {
        color: #FFFFFF;
        font-size: 21px;
        line-height: 1.41;

        @media only screen and (max-width: 890px) {
        font-size: 21px;
        }
      
        @media only screen and (max-width: 414px) {
        font-size: 21px;
        }
    }

    p {
        color: #FFFFFF;
        font-size: 21px;        
        line-height: 1.41;
        height:100%;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #FFFFFF;

        :hover {
            color: #FFFFFF;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        background: #0099cc;
        padding-top: 1.5rem;
        text-color: white;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;
