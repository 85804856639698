import { lazy, Suspense, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import TablessHeader from "../components/TablessHeader";
import { Styles } from "../styles/styles";
import routes from "./config";






const Router = () => {


  
  let location = useLocation();
    useEffect(() => {
      console.log(location)
    }, [location]);

  return (
    <Suspense fallback={null}>
      <Styles />
      {location.pathname === "/" ?<Header />:<TablessHeader/>}
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
      <Footer />
    </Suspense>
  );
};

export default Router;
